import { createTheme, ThemeProvider } from "@mui/material/styles";
import React from "react";
import AppProvider from "./layouts/AppProvider";
import RouteList from "./routes/RouteList";

const theme = createTheme({
  // ------------------- color palette for medical circles ----------------
  palette: {
    primaryBlue: "#546FFF",
    secondaryBlue: "#BAC8FF",
    lightBlueText: "#54577A",
    bgWhite:"#fff",
    textBalck:"#000"
  },


  typography: {
    fontFamily: "Plus Jakarta Sans, sans-serif",

    // --------- for big Heading => size-24 , weight: 600 ---------------
    h1: {
      fontSize: "1.5rem",
      color: "black",
      fontWeight: 600,
      marginBottom: "1.25rem",
    },

    // --------- for normal Heading => size-16 , weight: 600 ---------------
    h2: {
      fontSize: "1rem",
      color: "black",
      marginBottom: ".4rem",
      fontWeight: 600,
    },
    // --------- for small Heading => size-16 , weight: 500 ---------------
    h3: {
      fontSize: "1rem",
      color: "black",
      marginBottom: ".4rem",
      fontWeight: 500,
    },
    // --------- Primary color Text => size-14 , weight: 500 ---------------
    subtitle1: {
      fontSize: ".875rem",
      color: "#546FFF",
      marginBottom: ".3rem",
      fontWeight: 500,
    },
    menu: {
      fontSize: ".875rem",
      color: "white",
      marginBottom: ".3rem",
      fontWeight: 500,
    },
    // --------- secondary color Text => size-12 , weight: 500 ---------------
    subtitle2: {
      fontSize: ".75rem",
      color: "#54577A",
      marginBottom: ".3rem",
      fontWeight: 500,
    },

    // --------- Normal paragraph => size-14 , weight: 500 ---------------
    body1: {
      color: "black",
      fontSize: ".875rem",
      fontWeight: 500,
    },
    // --------- small paragraph => size-12 , weight: 500 ---------------
    body2: {
      color: "black",
      fontSize: ".7rem",
      fontWeight: 600,
    },
  },
});

const App = () => {
  return (
    <div>
      <ThemeProvider theme={theme}>
        <AppProvider>
          <RouteList />
        </AppProvider>
      </ThemeProvider>
    </div>
  );
};

export default App;
